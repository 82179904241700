import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';

import styles from './customers.module.scss';

const cx = classNames.bind(styles);

const Customers = ({ className }) => {
  const {
    wpSharedBlock: {
      acf: { title, logos },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "customers" }) {
          acf {
            title
            logos {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 60)
                }
              }
              altText
            }
          }
        }
      }
    `
  );

  return (
    <section className={cx('wrapper', className)}>
      <div className="container">
        <h2 className={cx('title')}>{title}</h2>
        <div className={cx('logos')}>
          {logos.map(({ localFile, altText }, index) => {
            const image = localFile.childImageSharp;
            return (
              <div key={index}>
                <GatsbyImage className={cx('logo')} image={getImage(image)} alt={altText} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

Customers.propTypes = {
  className: PropTypes.string,
};

Customers.defaultProps = {
  className: null,
};

export default Customers;
