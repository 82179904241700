import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import IconicBlock from 'components/shared/iconic-block';
import Heading from 'components/shared/heading';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';

import styles from './contacts.module.scss';

const cx = classNames.bind(styles);

const Contacts = ({ title, subtitle, items }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')} tag="h2" size="xl">
        {title}
      </Heading>
      <Heading className={cx('subtitle')} tag="p" size="lg" role="doc-subtitle">
        {subtitle}
      </Heading>

      <ul className={cx('columns is-multiline is-centered', 'iconic-blocks-wrapper')}>
        {items.map((item, index) => (
          <li className={cx('column is-12-mobile is-4-tablet', 'iconic-block')} key={index}>
            <IconicBlock descriptionClassName={cx('iconic-block-description')} {...item} />
          </li>
        ))}
      </ul>

      <img className={cx('shape-1')} src={shape1} alt="" />
      <img className={cx('shape-2')} src={shape2} alt="" />
    </div>
  </section>
);

Contacts.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(IconicBlock.propTypes)).isRequired,
};

export default Contacts;
