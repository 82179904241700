/* eslint-disable no-undef */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import HubspotForm from 'components/shared/hubspot-form';
import { FORM_IDS } from 'constants/form';

import sendImpactEvent from 'utils/send-impact-event';
import shape from './svg/shape.svg';
import styles from './form.module.scss';

const cx = classNames.bind(styles);

const IMPACT_EVENT_ID = 31876;

const Form = ({ title, subtitle }) => {
  const formRef = useRef();
  const impactParams = {};

  const onFormSubmit = (e) => {
    const orderId = `OID-${Math.floor(Math.random() * Date.now())}`;
    const customerEmail = e.querySelector('input[name="email"]').value;
    const orderPromoCode = e.querySelector('input[name="promo_code"]')?.value || '';

    // hidden fields in Hubspot form
    const inputOrderId = e.querySelector(`.hs-input[name="ir_order_id"]`);
    const inputReferralCode = e.querySelector(`.hs-input[name="ir_referral_code"]`);

    impactParams.orderId = orderId;
    impactParams.customerId = '';
    impactParams.customerEmail = customerEmail;
    impactParams.orderPromoCode = orderPromoCode;

    inputOrderId.value = orderId;
    inputReferralCode.value = orderPromoCode;
  };

  const onFormSubmitted = () => {
    sendImpactEvent(IMPACT_EVENT_ID, impactParams);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, formRef.current?.offsetTop);
    }
  };

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <Heading className={cx('title')} size="xl">
          {title}
        </Heading>
        <Heading className={cx('subtitle')} tag="p" size="lg" role="doc-subtitle">
          {subtitle}
        </Heading>

        <div className={cx('form-wrapper')} ref={formRef}>
          <HubspotForm
            formId={FORM_IDS.REQUEST_A_QUOTE}
            targetId="hubspotRequestAQuoteForm"
            onFormSubmit={onFormSubmit}
            onFormSubmitted={onFormSubmitted}
          />
        </div>

        <img className={cx('shape')} src={shape} alt="" aria-hidden loading="eager" />
      </div>
    </section>
  );
};

Form.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Form;
