/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import Customers from 'components/shared/customers';
import Form from 'components/pages/get-a-quote/form';
import Contacts from 'components/pages/get-a-quote/contacts';
import { useLazyBlocks } from '../hooks/use-lazy-blocks';

const GetAQuote = ({ data: { wpPage: data }, pageContext, location }) => {
  const { reactedContent } = useLazyBlocks(data?.content || '');
  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      footerTheme="with-border"
      withBanner={pageContext.showBanner}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Form
        title={data.acf.headline}
        subtitle={data.acf.headlineSubtitle}
        buttonText={data.acf.section1ButtonText}
        displayFollowUpPage={data.acf.displayFollowUpPage}
        followUpRedirectUrl={data.acf.followUpRedirectUrl}
      />
      {reactedContent}
      <Contacts
        title={data.acf.section2Title}
        subtitle={data.acf.section2Subtitle}
        items={data.acf.section2Items}
      />
      <Customers />
    </LayoutMain>
  );
};

export default GetAQuote;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      acf {
        headline
        headlineSubtitle
        section1ButtonText
        displayFollowUpPage
        followUpRedirectUrl
        section2Title
        section2Subtitle
        section2Items {
          title
          description
          iconName
        }
      }
      ...wpPageSeo
    }
  }
`;
